import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import apolloClientProvider from '../../../apolloClientProvider';
import * as actions from '../../../store/actions'
import MDFeForm from './Form/MdfeForm';
import { listaUF, mascaraCpf, converteMoedaParaFloat, removeDots, formatMoney, validateCpfCnpj, somenteNumeros } from '../../../shared/utilities';
import { listaModais, tiposDocumentos, unidadesMedida, tiposEmitente, tiposDocumentoEmpresa  } from '../../../shared/mdfe';
import { tiposResponsavelSeguro, tiposDocumentoContratantes, mdfeTipoCarga, tipoPagamento, tipoComponente} from '../../../shared/mdfe';

const styles = {
    container: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
    }
}

const initialValues = {
    chave: '',
    modelo: 58,
    serie: undefined,
    numero: undefined,
    dataHoraEmissao: new Date(),
    dataHoraInicioViagem: null,
    modal: listaModais[0],
    tipoEmitente: tiposEmitente[1],
    ufInicio: { value: 28, label: listaUF.find(e => e.id === 28).sigla },
    ufDescarregamento: { value: 28, label: listaUF.find(e => e.id === 28).sigla },
    veiculo: { value: undefined, label: undefined },
    ufsPercurso: [],
    municipiosCarregamento: [],
    reboques: [],
    condutores: [],
    municipiosDescarregamento: [],
    valor: '',
    pesoBruto: '',
    unidade: {
        label: 'KG',
        value: 1
    },
    informacoesFisco: "",
    informacoesContribuinte: "",
    editavel: true,
    seguros: [],
    contratantes: [],
    ciots: [],
    carregamentoPosterior: false,
    cEAN: '',
    ncm: '',
    tpcarga: mdfeTipoCarga[0],
    xProd: '',
    cepCarregamento: '',
    cepDescarregamento: '',
    infLocal: false,
    mdfeInfPag: []
}

class PureMdfeCreateUpdate extends Component {

    state = {
        errors: null
    }

    resetForm = null;

    getTipoDocumento(documento) {
        if(validateCpfCnpj(documento))    
            return documento.length === 14 ?  
                    tiposDocumentoContratantes.find(t => t.label === 'CNPJ') : 
                    tiposDocumentoContratantes.find(t => t.label === 'CPF');

        return tiposDocumentoContratantes.find(t => t.label === 'ID Estrangeiro');
    }
    
    componentDidMount() {
        const id = this.props.match.params.id;

        let tipoEmitente = tiposEmitente[1];

        apolloClientProvider.client.query({ query: actions.GET_CONFIGURACAO, fetchPolicy: "network-only" })
            .then(({ data: { configuracao } }) => {
                tipoEmitente = configuracao
                    && configuracao.configuracaomdfe
                    && configuracao.configuracaomdfe.tipoemitente;
                tipoEmitente = tiposEmitente.find(e => e.enumString === tipoEmitente)

                const ufInicio = configuracao.configuracaomdfe.estado && {
                    label: configuracao.configuracaomdfe.estado,
                    value: listaUF.find(e => e.sigla === configuracao.configuracaomdfe.estado).id
                };

                this.resetForm({ values: { ...initialValues, tipoEmitente, ufInicio, ufDescarregamento: ufInicio } });
            })
            .then(() => {
                if (id) {
                    apolloClientProvider.client.query({ query: actions.GET_MDFE, variables: { id: id }, fetchPolicy: "network-only" })
                        .then(({ data: { mdfe } }) => {
                            const veiculo = (mdfe.mdfetransporterodoviario && mdfe.mdfetransporterodoviario.veiculo)
                                ? { value: mdfe.mdfetransporterodoviario.veiculo.id, label: mdfe.mdfetransporterodoviario.veiculo.placa }
                                : initialValues.veiculo;

                            const municipiosCarregamento = mdfe.municipioscarregamento ? mdfe.municipioscarregamento.map(val => ({
                                value: val.municipioid,
                                label: val.nome
                            })) : initialValues.municipiosCarregamento;

                            const reboques = (mdfe.mdfetransporterodoviario && mdfe.mdfetransporterodoviario.reboques) ? mdfe.mdfetransporterodoviario.reboques.map(val => ({
                                value: val.reboque.id,
                                label: val.reboque.placa
                            })) : initialValues.reboques;

                            const condutores = (mdfe.mdfetransporterodoviario && mdfe.mdfetransporterodoviario.condutores) ? mdfe.mdfetransporterodoviario.condutores.map(val => ({
                                value: val.motorista.id,
                                label: val.motorista.nome + ' - ' + mascaraCpf(val.motorista.cpfcnpj)
                            })) : initialValues.condutores;

                            const municipiosDescarregamento = [];
                            console.log(mdfe.municipiodocs);
                            if (mdfe.municipiodocs) {
                                mdfe.municipiodocs.forEach(municipiodoc => {
                                    municipiodoc.documentos.forEach(doc => {
                                        municipiosDescarregamento.push({
                                            id: municipiodoc.id,
                                            chave: doc.chave,
                                            municipio: {
                                                label: municipiodoc.nomemunicipio,
                                                value: municipiodoc.municipioid
                                            },
                                            tipo: tiposDocumentos.find(f => f.enumString === doc.tipodocumento),
                                            segcodbarra: doc.segcodbarra !== null ? doc.segcodbarra : undefined
                                        })
                                    })
                                })
                            }

                            const ufsPercurso = mdfe.ufspercurso ? mdfe.ufspercurso.split(',').map(val => ({
                                label: val, value: listaUF.find(e => e.sigla === val).id
                            }))
                                : initialValues.ufsPercurso;

                            const modal = mdfe.modalidade ? listaModais.find(e => e.enumString === mdfe.modalidade)
                                : initialValues.modal;

                            const seguros = mdfe.seguros && mdfe.seguros.map(s => ({
                                ...s,
                                tipoDocumento: s.cpfcnpjresponsavel ?
                                    (s.cpfcnpjresponsavel.length > 14 ?
                                        tiposDocumentoEmpresa.find(t => t.label === 'CNPJ') : tiposDocumentoEmpresa.find(t => t.label === 'CPF'))
                                    : tiposDocumentoEmpresa.find(t => t.label === 'CNPJ'),
                                responsavel: tiposResponsavelSeguro.find(t => t.enumString === s.responsavel),
                            }));

                            const contratantes = mdfe.mdfetransporterodoviario &&
                                mdfe.mdfetransporterodoviario.contratantes ?
                                mdfe.mdfetransporterodoviario.contratantes.split(',').map(c => ({
                                    documento: c.trim(),
                                    tipoDocumento: c.trim() && this.getTipoDocumento(c.trim())
                                })) : initialValues.contratantes;

                            let tipoEmitente = tiposEmitente[1];

                            const ciots = mdfe.mdfetransporterodoviario &&
                                mdfe.mdfetransporterodoviario.ciots ?
                                mdfe.mdfetransporterodoviario.ciots.map(c => ({
                                    documento: c.cpfcnpj,
                                    tipoDocumento: c.cpfcnpj &&
                                        (c.cpfcnpj.length > 14 ?
                                            tiposDocumentoEmpresa.find(t => t.label === 'CNPJ') : tiposDocumentoEmpresa.find(t => t.label === 'CPF')),
                                    ciot: c.numerociot
                                })) : initialValues.ciots;
                            
                            let mdfeInfPag =  null;
                            mdfeInfPag = mdfe.mdfeInfPag.length !== 0 ?
                            mdfe.mdfeInfPag.map((inf) => {
                                const comp = inf.comp.length !== 0 ? 
                                inf.comp.map( value => {
                                    return {
                                        id: value.id,
                                        tpcomp: tipoComponente.find(t => t.enumString === value.tpcomp),
                                        vcomp: value.vcomp,
                                        xcomp: value.xcomp !== null && value.xcomp !== "" ? value.xcomp : undefined
                                    }
                                })
                                : [];

                                const infPrazo = inf.infPrazo.length !== 0 ?
                                inf.infPrazo.map( value => {
                                    return {
                                        id: value.id,
                                        nparcela: value.nparcela,
                                        vparcela: value.vparcela,
                                        dvenc: value.dvenc
                                    }
                                })
                                : [];

                                return {
                                    id: inf.id,
                                    tpdocumento: tiposDocumentoContratantes.find(t => t.label === inf.tpdocumento),
                                    vcontrato: inf.vcontrato,
                                    xnome: inf.xnome !== null && inf.xnome !== "" ? inf.xnome : undefined,
                                    cpfCnpjId: inf.cpfCnpjId,
                                    indpag: tipoPagamento.find(t => t.enumString === inf.indpag),
                                    comp: comp,
                                    infPrazo: infPrazo,
                                    codagencia: inf.codagencia,
                                    codbanco: inf.codbanco,
                                }
                            }): [];

                            apolloClientProvider.client.query({ query: actions.GET_CONFIGURACAO, fetchPolicy: "network-only" })
                                .then(({ data: { configuracao } }) => {
                                    tipoEmitente = configuracao
                                        && configuracao.configuracaomdfe
                                        && configuracao.configuracaomdfe.tipoemitente;
                                    tipoEmitente = tiposEmitente.find(e => e.enumString === tipoEmitente)
                                    
                                    const resetData = {
                                        chave: mdfe.chave ? mdfe.chave : initialValues.chave,
                                        modelo: mdfe.modelo ? mdfe.modelo : initialValues.modelo,
                                        serie: mdfe.serie ? mdfe.serie : initialValues.serie,
                                        numero: mdfe.numero ? mdfe.numero : initialValues.numero,
                                        dataHoraEmissao: mdfe.datahoraemissao ? mdfe.datahoraemissao : initialValues.dataHoraEmissao,
                                        dataHoraInicioViagem: mdfe.datahorainicioviagem ? mdfe.datahorainicioviagem : initialValues.dataHoraInicioViagem,
                                        modal: modal,
                                        tipoEmitente: mdfe.tipoemitente ? tiposEmitente.find(t => t.enumString === mdfe.tipoemitente) : initialValues.tipoEmitente,
                                        ufDescarregamento: mdfe.uffim ? { label: mdfe.uffim, value: listaUF.find(e => e.sigla === mdfe.uffim).id } : initialValues.ufDescarregamento,
                                        ufInicio: mdfe.ufinicio ? { label: mdfe.ufinicio, value: listaUF.find(e => e.sigla === mdfe.ufinicio).id } : initialValues.ufInicio,
                                        ufInicioSelected: mdfe.ufinicio ? { label: mdfe.ufinicio, value: listaUF.find(e => e.sigla === mdfe.ufinicio).id } : initialValues.ufInicio,
                                        veiculo: veiculo,
                                        ufsPercurso: ufsPercurso,
                                        municipiosCarregamento: municipiosCarregamento,
                                        mdfeTransporteRodoviarioId: mdfe.mdfetransporterodoviario && mdfe.mdfetransporterodoviario.id ? mdfe.mdfetransporterodoviario.id : null,
                                        municipioDocsId: mdfe.municipiodocs & mdfe.municipiodocs.id ? mdfe.municipiodocs.id : null,
                                        reboques: reboques,
                                        condutores: condutores,
                                        municipiosDescarregamento: municipiosDescarregamento,
                                        mdfeTotalizadorId: mdfe.mdfetotalizador && mdfe.mdfetotalizador.id ? mdfe.mdfetotalizador.id : null,
                                        valor: mdfe.mdfetotalizador && mdfe.mdfetotalizador.valorcarga ? ("R$" + formatMoney(mdfe.mdfetotalizador.valorcarga, 2, ',', '.')) : initialValues.valor,
                                        pesoBruto: mdfe.mdfetotalizador && mdfe.mdfetotalizador.pesototalcarga ? mdfe.mdfetotalizador.pesototalcarga : initialValues.pesoBruto,
                                        unidade: mdfe.mdfetotalizador && mdfe.mdfetotalizador.unidademedida ? {
                                            label: mdfe.mdfetotalizador.unidademedida,
                                            value: unidadesMedida[mdfe.mdfetotalizador.unidademedida]
                                        } : initialValues.unidade,
                                        informacoesFisco: mdfe.infadfisco ? mdfe.infadfisco : initialValues.informacoesFisco,
                                        informacoesContribuinte: mdfe.infcomplementar ? mdfe.infcomplementar : initialValues.informacoesContribuinte,
                                        editavel: typeof mdfe.editavel !== 'undefined' ? mdfe.editavel : initialValues.editavel,
                                        seguros,
                                        contratantes,
                                        carregamentoPosterior: mdfe.carregamentoPosterior,
                                        ciots,
                                        ncm: mdfe.mdfeProdPred && mdfe.mdfeProdPred.ncm !== null ? 
                                                    mdfe.mdfeProdPred.ncm : initialValues.ncm,
                                        xProd: mdfe.mdfeProdPred && mdfe.mdfeProdPred.xProd !== null ? 
                                                    mdfe.mdfeProdPred.xProd : initialValues.xProd,
                                        cEAN: mdfe.mdfeProdPred && mdfe.mdfeProdPred.cEAN !== null ? 
                                                    mdfe.mdfeProdPred.cEAN : initialValues.cEAN,
                                        infLocal: mdfe.mdfeProdPred !== null && 
                                                  (mdfe.mdfeProdPred.cepCarregamento !== null || 
                                                  mdfe.mdfeProdPred.cepDescarregamento !== null),
                                        tpcarga:mdfe.mdfeProdPred && mdfe.mdfeProdPred.tpcarga ? 
                                                    mdfeTipoCarga.find(t => t.enumString === mdfe.mdfeProdPred.tpcarga) : initialValues.tpcarga,
                                        cepCarregamento: mdfe.mdfeProdPred && mdfe.mdfeProdPred.cepCarregamento ? 
                                                mdfe.mdfeProdPred.cepCarregamento : initialValues.cepCarregamento,
                                        cepDescarregamento: mdfe.mdfeProdPred && mdfe.mdfeProdPred.cepDescarregamento ? 
                                                mdfe.mdfeProdPred.cepDescarregamento : initialValues.cepDescarregamento,
                                        mdfeInfPag: mdfeInfPag.length !== 0 ? mdfeInfPag : initialValues.mdfeInfPag
                                    }

                                    this.resetForm({ values: resetData });
                                });
                        });
                }
            })
    }

    ajusteSubmitNumeroOuSerie = (input) => (input === 0) || (input === "") ? undefined : input

    handleSubmit = (values, addHandlers) => {

        const id = this.props.match.params.id;

        const municipiosCarregamento = values.municipiosCarregamento.map(val => ({
            municipioid: val.value,
            nome: val.label
        }));

        const municipioDocsTemp = {};

        if (values.carregamentoPosterior) {
            municipioDocsTemp[0] = {
                municipioid: municipiosCarregamento[0].municipioid,
                nomemunicipio: municipiosCarregamento[0].nome,
                documentos: []
            }
        } else {
            values.municipiosDescarregamento.forEach(val => {
                if (!municipioDocsTemp[val.municipio.value]) {
                    municipioDocsTemp[val.municipio.value] = {
                        municipioid: val.municipio.value,
                        nomemunicipio: val.municipio.label,
                        documentos: []
                    }
                }

                municipioDocsTemp[val.municipio.value].documentos.push({
                    chave: val.chave,
                    tipodocumento: val.tipo.enumString,
                    segcodbarra: val.tipo.value > 2 ? val.segcodbarra : null
                })
            });
        }

        const municipioDocs = Object.values(municipioDocsTemp);
        municipioDocs.id = values.municipioDocsId;

        const contratantes = values.contratantes
            .reduce((prev, current) => prev += current.documento + ',', '')
            .slice(0, -1);

        const ciots = values.ciots.map(c => {
            return {
                cpfcnpj: c.documento,
                numerociot: c.ciot
            }
        });

        const mdfeTransporteRodoviario = {
            id: values.mdfeTransporteRodoviarioId,
            condutores: values.condutores.map(val => ({ condutorid: val.value })),
            reboques: values.reboques.map(val => ({ reboqueid: val.value })),
            veiculoid: values.veiculo.value,
            contratantes,
            ciots
        }

        const ufsPercurso = values.ufsPercurso
            .reduce((prev, current) => prev += current.label + ',', '')
            .slice(0, -1);

        const seguros = values.seguros.map(s => ({
            //id: s.id,
            responsavel: s.responsavel.enumString,
            cpfcnpjresponsavel: s.cpfcnpjresponsavel,
            nomeseguradora: s.nomeseguradora,
            cnpjseguradora: s.cnpjseguradora,
            numeroapolice: s.numeroapolice,
            numerosdasaverbacoes: s.numerosdasaverbacoes,
        }));

        let mdfeProdPred = null;
        if(values.xProd !== initialValues.xProd &&  
            values.ncm !== initialValues.ncm && values.cEAN !== initialValues.cEAN) 
            mdfeProdPred = {
                tpcarga: values.tpcarga.value,
                xProd: values.xProd,
                ncm: values.ncm,
                cEAN: values.cEAN,
                cepCarregamento: values.cepCarregamento,
                cepDescarregamento: values.cepDescarregamento,
            }
        
        let mdfeInfPag = undefined;
        mdfeInfPag = values.mdfeInfPag.length !== 0 ? values.mdfeInfPag.map(inf =>{
            const infPrazo = inf.infPrazo.length !== 0 ? 
            inf.infPrazo.map((value, index) =>{
                return {
                    nparcela: index+1,
                    dvenc: value.dvenc,
                    vparcela: converteMoedaParaFloat(removeDots(value.vparcela))
                }}): [];

            const comp = inf.comp.length !== 0 ? 
            inf.comp.map(comp => {
                return{
                    vcomp: converteMoedaParaFloat(removeDots(comp.vcomp)),
                    tpcomp: comp.tpcomp.value,
                    xcomp: comp.xcomp !== "" ? comp.xcomp : null
                }}): [];
            
            return {
                id: inf.id,
                tpdocumento: inf.tpdocumento.value,
                cpfCnpjId: somenteNumeros(inf.cpfCnpjId),
                indpag: inf.indpag.value,
                infPrazo: infPrazo,
                comp: comp,
                vcontrato: converteMoedaParaFloat(removeDots(inf.vcontrato)),
                xnome: inf.xnome !== "" ? inf.xnome : null,
                codagencia: inf.codagencia,
                codbanco: inf.codbanco,
            }
        }) : undefined;

        const submitData = {
            id,
            //chave: values.chave,
            serie: this.ajusteSubmitNumeroOuSerie(values.serie),
            numero: this.ajusteSubmitNumeroOuSerie(values.numero),
            datahoraemissao: values.dataHoraEmissao,
            datahorainicioviagem: values.dataHoraInicioViagem,
            ufinicio: values.ufInicio.label,
            uffim: values.ufDescarregamento.label,
            municipioscarregamento: municipiosCarregamento,
            municipiodocs: municipioDocs,
            mdfetransporterodoviario: mdfeTransporteRodoviario,
            mdfetotalizador: {
                id: values.mdfeTotalizadorId,
                valorcarga: converteMoedaParaFloat(removeDots(values.valor)),
                unidademedida: values.unidade.label,
                pesototalcarga: parseFloat(removeDots(values.pesoBruto)),
            },
            mdfeProdPred,
            infadfisco: values.informacoesFisco ? values.informacoesFisco : undefined,
            infcomplementar: values.informacoesContribuinte ? values.informacoesContribuinte : undefined,
            modalidade: values.modal.enumString,
            ufspercurso: ufsPercurso,
            tipoemitente: values.tipoEmitente.enumString,
            seguros,
            carregamentoPosterior: values.carregamentoPosterior,
            mdfeInfPag: mdfeInfPag ? mdfeInfPag : undefined
        };

        addHandlers(
            apolloClientProvider.client.mutate(
                {
                    mutation: actions.SET_MDFE,
                    variables: { mdfe: submitData },
                    refetchQueries: [{ query: actions.GET_MDFES }],
                })
                .then(({ data }) => {
                    actions.OPEN_SNACKBAR('MDFe cadastrado!', false, true);
                    this.props.history.push('/mdfe');
                })
        )
    }

    handleClose = () => {
        this.props.history.goBack();
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.container}>
                <MDFeForm
                    onSubmit={this.handleSubmit}
                    initialValues={initialValues}
                    handleClose={this.handleClose}>
                    {({ form, isDirty, isSubmitting, handleCancel }) => (
                        <React.Fragment>
                            {this.resetForm = handleCancel}
                            {form}
                        </React.Fragment>
                    )}
                </MDFeForm>
                {this.state.errors}
            </div >
        );
    };
}

export default withStyles(styles)(withRouter(PureMdfeCreateUpdate));