import React from 'react';

export function getSorting(order, orderBy) {
    return order === 'desc'
        ? (a, b) => (b[orderBy] < a[orderBy] ? -1 : 1)
        : (a, b) => (a[orderBy] < b[orderBy] ? -1 : 1);
}

export function validateCpfCnpj(cpfCnpj) {
    let arr;

    function buildDigit(arr) {

        let isCpf = arr.length < 11,
            digit = arr.map((val, idx) => (val * ((!isCpf ? idx % 8 : idx) + 2))).reduce((total, current) => total + current) % 11;

        return digit < 2 ? 0 : 11 - digit;
    };

    let cpfCnpj_copy = cpfCnpj.replace(/\D/g, '');

    if ([11, 14].indexOf(cpfCnpj_copy.length) < 0) return false;

    arr = cpfCnpj_copy.split('').reverse().slice(2);

    arr.unshift(buildDigit(arr));
    arr.unshift(buildDigit(arr));
    return cpfCnpj_copy === arr.reverse().join('');
};

export function validateIdEstrangeiro(idEstrangeiro = ''){
    const rule = /[a-zA-Z0-9:.+-/()]+/g;

    const match  =  idEstrangeiro.match(rule) != null ? 
        idEstrangeiro.match(rule)[0] === idEstrangeiro : false;
        
    return  match && idEstrangeiro.length >= 2 && idEstrangeiro.length <= 20;
}

export function validateValorMonetario(valor = ''){
    const rule = /^([\d(?:.\d{3})*]{1,13})(?:,\d{2}){0,1}$/;
    valor = valor.replace('R$','');

    return valor.match(rule) != null ?
        valor.match(rule)[0] === valor : false;
}

export const formatTelefone = (telefone) => {
    const telString = telefone + '';

    if (telString.length === 0)
        return '';

    if (telString.length > 11)
        return telString.substring(0, 11);


    const ddd = telString.substring(0, 2);

    if (telString.length === 11) {
        const parte1 = telString.substring(2, 7);
        const parte2 = telString.substring(7, 11);
        return '(' + ddd + ') ' + parte1 + '-' + parte2;
    }

    if (telString.length < 11) {
        const parte1 = telString.substring(2, 6);
        const parte2 = telString.substring(6, 10);
        return '(' + ddd + ') ' + parte1 + '-' + parte2;
    }
}

export const listaUF = [
    { id: 12, sigla: 'AC' },
    { id: 27, sigla: 'AL' },
    { id: 13, sigla: 'AM' },
    { id: 16, sigla: 'AP' },
    { id: 29, sigla: 'BA' },
    { id: 23, sigla: 'CE' },
    { id: 53, sigla: 'DF' },
    { id: 32, sigla: 'ES' },
    { id: 52, sigla: 'GO' },
    { id: 21, sigla: 'MA' },
    { id: 31, sigla: 'MG' },
    { id: 50, sigla: 'MS' },
    { id: 51, sigla: 'MT' },
    { id: 15, sigla: 'PA' },
    { id: 25, sigla: 'PB' },
    { id: 26, sigla: 'PE' },
    { id: 22, sigla: 'PI' },
    { id: 41, sigla: 'PR' },
    { id: 33, sigla: 'RJ' },
    { id: 24, sigla: 'RN' },
    { id: 11, sigla: 'RO' },
    { id: 14, sigla: 'RR' },
    { id: 43, sigla: 'RS' },
    { id: 42, sigla: 'SC' },
    { id: 28, sigla: 'SE' },
    { id: 35, sigla: 'SP' },
    { id: 17, sigla: 'TO' },
    { id: 99, sigla: 'EX' },
];

export const getCookie = (cookieName) => {
    if (document.cookie.length > 0) {
        let cookieStart = document.cookie.indexOf(cookieName + '=');
        if (cookieStart !== -1) {
            cookieStart = cookieStart + cookieName.length + 1;
            let cookieEnd = document.cookie.indexOf(';', cookieStart);
            if (cookieEnd === -1) {
                cookieEnd = document.cookie.length;
            }
            return window.unescape(document.cookie.substring(cookieStart, cookieEnd));
        }
    }
    return '';
};

/**
 * Remove a máscara de Cpf ou Cnpj
 * @param {string} input String a retirar máscara
 * @returns {string} Somente números do CPF/CNPJ
 */
export const somenteNumeros = (input) => {
    return input.replace(/\D/g, '');
}

/**
 * Converte um arquivo em base64
 * @param {string} file URL do arquivo
 * @returns {string} Promise do arquivo em base64
 */
export const getBase64 = file => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}


/**
 * Converte uma string em formato Moeda para Float
 * @param {string} input Moeda exemplo: R$ 125,32
 * @returns {float} Valor em float
 */
export const converteMoedaParaFloat = (input) => {
    const initial = input + '';
    const regex = /([+-]?[0-9|^.|^,]+)[.|,]([0-9]+)$/igm;
    const result = regex.exec(initial);
    const floatResult = result ? result[1].replace(/[.,]/g, "") + "." + result[2] : initial.replace(/[^0-9-+]/g, "");
    return floatResult;
}

export function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
  
      const negativeSign = amount < 0 ? "-" : "";
  
      let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      let j = (i.length > 3) ? i.length % 3 : 0;
  
      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
      console.log(e)
    }
  };

/**
 * Remove itens duplicados de um array a partir do valor de uma propriedade
 * @param {Array} items Array com items possívelmente duplicados
 * @param {string} prop Nome da propriedade a ser considerada na comparação
 * @returns {Array} Array com elementos distintos
 */
export const getArrayWithUniqueItems = (items, prop = 'value') => {
    const a = [];
    const u = [];
    for (var i = 0, l = items.length; i < l; i++) {
        if (u.indexOf(items[i][prop]) === -1) {
            u.push(items[i][prop])
            a.push(items[i]);
        }
    }

    return a;
    // return items.filter((obj, pos, arr) => {
    //     return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    // });
}

export function mascaraCpf(valor) {
    return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
}

export function mascaraCnpj(valor) {
    return valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5");
}

export function uppercaseFirstLetterOnly(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

/**
 * Imprime a página HTML em uma nova aba
 * @param {string} html HTML a ser impresso
 * @param {Window} [window=null] Janela utilizada na impressão
 */
export function imprimirHtml(html, windowTitle, window = null) {
    if (!window)
        window = window.open();
    window.document.write(html);
    window.document.title = windowTitle;
    window.focus();
    window.print();
    window.close();
}

export const downloadFile = (content, fileName, mimeType) => {
    // Create a new Blob object using the 
    //response data of the onload object
    var blob = new Blob([content], { type: mimeType });
    //Create a link element, hide it, direct 
    //it towards the blob, and then 'click' it programatically
    let a = document.createElement("a");
    a.style = "display: none";
    document.body.appendChild(a);
    //Create a DOMString representing the blob 
    //and point the link element towards it
    let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    //programatically click the link to trigger the download
    a.click();
    //release the reference to the file by revoking the Object URL
    window.URL.revokeObjectURL(url);
}

/**
 * Remove os caracteres . de uma string
 * @param {string} input String de entrada
 */
export function removeDots(input) {
    input = input.toString();
    return input.replace(/\./g, '');
}


export function ReactIsInDevelomentMode() {
    return '_self' in React.createElement('div');
}

export const getAmountOfRowsToRender = (rowHeight = 52, toolbarHeight = 296) => Math.floor((window.innerHeight - toolbarHeight) / rowHeight)

export const fetchFromObject = (obj, prop) => {

    if (typeof obj === 'undefined') {
        return false;
    }

    var _index = prop.indexOf('.')
    if (_index > -1) {
        return fetchFromObject(obj[prop.substring(0, _index)], prop.substr(_index + 1));
    }

    return obj[prop];
}

export const padNumber = (num, size) => {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
}

export const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }