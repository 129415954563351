import axios from 'axios';
import { ReactIsInDevelomentMode } from './shared/utilities';

const developmentMode = ReactIsInDevelomentMode();

export const baseURL = developmentMode ? 'https://localhost:44385/api/' : 'https://zmdfe-homologacao.zeusautomacao.com.br/api/';

const instance = axios.create({
    baseURL,
    withCredentials: true,
});

instance.defaults.withCredentials = true;

export default instance;