export const listaTiposProprietario = [
    { value: 0, label: 'TAC Agregado', enumString: 'TAC_AGREGADO' },
    { value: 1, label: 'TAC Independente', enumString: 'TAC_INDEPENDENTE' },
    { value: 2, label: 'Outros', enumString: 'OUTROS' },
];

export const listaTiposRodado = [
    { value: 1, label: 'Truck', enumString: 'TRUCK' },
    { value: 2, label: 'Toco', enumString: 'TOCO' },
    { value: 3, label: 'Cavalo Mecânico', enumString: 'CAVALO_MECANICO' },
    { value: 4, label: 'Van', enumString: 'VAN' },
    { value: 5, label: 'Utilitário', enumString: 'UTILITARIO' },
    { value: 6, label: 'Outros', enumString: 'OUTROS' },
];

export const listaTiposCarroceria = [
    { value: 0, label: 'Não aplicável', enumString: 'NAO_APLICAVEL' },
    { value: 1, label: 'Aberta', enumString: 'ABERTA' },
    { value: 2, label: 'Fechada/Baú', enumString: 'FECHADA_BAU' },
    { value: 3, label: 'Granelera', enumString: 'GRANELERA' },
    { value: 4, label: 'Porta Container', enumString: 'PORTA_CONTAINER' },
    { value: 5, label: 'Sider', enumString: 'SIDER' },
];

export const listaModais = [
    { value: 1, label: 'Rodoviário', enumString: 'RODOVIARIO' },
    { value: 2, label: 'Aéreo', enumString: 'AEREO' },
    { value: 3, label: 'Aquaviário', enumString: 'AQUAVIARIO' },
    { value: 4, label: 'Ferroviário', enumString: 'FERROVIARIO' },
];

export const tiposDocumentos = [
    { value: 1, label: 'NFE', enumString: 'NFE' },
    { value: 2, label: 'CTE', enumString: 'CTE' },
    { value: 3, label: 'NFE FS-DA', enumString: 'NFEFSDA' },
    { value: 4, label: 'CTE FS-DA', enumString: 'CTEFSDA' },
];


export const unidadesMedida = {
    KG: 1,
    TON: 2
};
export const versoesServico = [
    { value: 1, label: '1.00', enumString: 'VERSAO_100' },
    { value: 2, label: '3.00', enumString: 'VERSAO_300' },
];

export const tiposAmbiente = [
    { value: 1, label: 'Produção', enumString: 'PRODUCAO' },
    { value: 2, label: 'Homologação', enumString: 'HOMOLOGACAO' },
];

export const tiposTransportador = [
    { value: 0, label: 'Não informado', enumString: '' },
    { value: 1, label: 'ETC', enumString: 'ETC' },
    { value: 2, label: 'TAC', enumString: 'TAC' },
    { value: 3, label: 'CTC', enumString: 'CTC' },
];

export const tiposEmitente = [
    { value: 1, label: 'Prestador de serviço de transporte', enumString: 'PRESTADOR_SERVICO_DE_TRANSPORTE' },
    { value: 2, label: 'Transportador de carga própria', enumString: 'TRANSPORTADOR_CARGA_PROPRIA' },
    { value: 3, label: 'Prestador de serviço de transporte - CT-e Globalizado', enumString: 'PRESTADOR_SERVICO_DE_TRANSPORTE_C_TE_GLOBALIZADO' },
];

export const situacoesMdfe = {
    salvo: { value: 1, label: 'Salvo', enumString: 'SALVO' },
    emProcessamento: { value: 2, label: 'Em processamento', enumString: 'EM_PROCESSAMENTO' },
    assinado: { value: 3, label: 'Assinado', enumString: 'ASSINADO' },
    autorizado: { value: 4, label: 'Autorizado', enumString: 'AUTORIZADO' },
    encerrado: { value: 5, label: 'Encerrado', enumString: 'ENCERRADO' },
    cancelado: { value: 6, label: 'Cancelado', enumString: 'CANCELADO' },
    rejeitado: { value: 7, label: 'Rejeitado', enumString: 'REJEITADO' },
};

export const tiposResponsavelSeguro = [
    { value: 1, label: 'Emitente do MDF-e', enumString: 'EMITENTE_DO_MD_FE' },
    { value: 2, label: 'Responsável pela Contratação', enumString: 'RESPONSAVEL_PELA_CONTRATACAO' },
];

export const tiposDocumentoEmpresa = [
    { label: 'CPF', value: false },
    { label: 'CNPJ', value: true },
]

export const tiposDocumentoContratantes = [
    { label: 'CPF', value: 1 },
    { label: 'CNPJ', value: 2 },
    { label: 'ID Estrangeiro', value: 3 },
]

export const getSituacaoLabel = (situacao) => {
    return Object.values(situacoesMdfe).find(s => s.enumString === situacao).label;
}

export const getTiposCarroceriaLabel = (tipo) => {
    return Object.values(listaTiposCarroceria).find(s => s.enumString === tipo).label;
}

export const getTiposRodadoLabel = (tipo) => {
    return Object.values(listaTiposRodado).find(s => s.enumString === tipo).label;
}

//Formatos de certificados suportados
export const SUPPORTED_CERTIFICATE_FORMATS = ['application/x-pkcs12', '.pfx']

//Mensagens de Alertas
export const alertMessages = {
    ALERT_LINKED_DOCUMENTS: `Documentos emitidos como carregamento posterior, não podem ter inicialmente documentos vinculados.\nDocumentos abaixo serão desconsiderados.`,
    ALERT_CLEAN_MUNICIPIOS: 'Alterar a UF de Carregamento irá limpar a lista de municípios. Você tem certeza que deseja alterar?',
    INFO_PRODUTO_PREDOMINANTE: `Campos Requeridos para o Tipo Emitente diferente de Transportador de Carga Própria e com apenas um documento informado para o transporte.`,
    INFO_CODBARRAS: `Campo Requerido apenas para documentos em situação de contigência (FS-DA).`
}

export const mdfeTipoCarga = [
    { value: 1,  label: 'Granel Sólido', enumString: 'GRANEL_SOLIDO' },
    { value: 2,  label: 'Granel Líquido', enumString: 'GRANEL_LIQUIDO' },
    { value: 3,  label: 'Frigorificada', enumString: 'FRIGORIFICADA' },
    { value: 4,  label: 'Conteinerizada', enumString: 'CONTEINERIZADA' },
    { value: 5,  label: 'Carga Geral', enumString: 'CARGA_GERAL' },
    { value: 6,  label: 'Neogranel', enumString: 'NEOGRANEL' },
    { value: 7,  label: 'Perigosa (granel sólido)', enumString: 'PERIGOSA_GRANEL_SOLIDO' },
    { value: 8,  label: 'Perigosa (granel líquido)', enumString: 'PERIGOSA_GRANEL_LIQUIDO' },
    { value: 9,  label: 'Perigosa (carga frigorificada)', enumString: 'PERIGOSA_CARGA_FRIGORIFICADA' },
    { value: 10,  label: 'Perigosa (conteinerizada)', enumString: 'PERIGOSA_CONTEINERIZADA' },
    { value: 11,  label: 'Perigosa (carga geral)', enumString: 'PERIGOSA_CARGA_GERAL' }
];

export const tipoPagamento = [
    { value: 0,  label: 'Pagamento à Vista', enumString: 'PAGAMENTO_VISTA' },
    { value: 1,  label: 'Pagamento à Prazo', enumString: 'PAGAMENTO_PRAZO' }
];

export const tipoComponente = [
    { value: 1,  label: 'Vale Pédagio', enumString: 'VALE_PEDAGIO' },
    { value: 2,  label: 'Impostos, Taxas e Contribuições', enumString: 'IMPOSTOS' },
    { value: 3,  label: 'Despesas', enumString: 'DESPESAS' },
    { value: 99,  label: 'Outros', enumString: 'OUTROS' }
];

export const certificadoAlerts = [
    { value: 0, message: 'Certificado com data de validade expirada. Por favor atualize-o.'},
    { value: 1, message: 'Certicado expira hoje. Por favor atualize-o para o funcionamento completo.'},
    { value: 2, message: 'Resta apenas 1 dia para o vencimento do Certificado.'},
    { value: 3, message: 'Restam # dias para o vencimento do Certificado.'}
];
